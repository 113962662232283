import { useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Input,
  FormHelperText,
  Box,
  Divider,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";

function Spendrequest({ clicked }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  useEffect(() => {
    if (clicked && !isOpen) {
      onOpen();
    }
    return () => {
      clicked = false;
    };
  }, [clicked]);
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        closeOnOverlayClick={false}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent pt={0} pb={6}>
          <ModalHeader color="orange.800">New Spend Request</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody pb={12}>
            <Box py={8} mx={12}>
              <form>
                <FormControl mt={4} isRequired>
                  <FormLabel htmlFor="date">Date</FormLabel>
                  <Input
                    id="date"
                    type="date"
                    size="sm"
                    width={"auto"}
                    variant={"filled"}
                  />
                  {/* <FormHelperText>Please enter the date</FormHelperText> */}
                </FormControl>
                <FormControl mt={4} isRequired>
                  <FormLabel htmlFor="total">Amount</FormLabel>
                  {/* <Input id="total" type="number" size="sm" width={"auto"} /> */}
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1em"
                      children="$"
                      pb="6.5px"
                    />
                    <Input
                      placeholder="Enter amount"
                      type="number"
                      size="sm"
                      width={"auto"}
                      variant={"filled"}
                    />
                  </InputGroup>
                  {/* <FormHelperText>Please enter the total amount</FormHelperText> */}
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel htmlFor="category">Category</FormLabel>
                  <Select id="category" size="sm" variant={"filled"}>
                    <option value="">Select a category</option>
                    <option value="groceries">Groceries</option>
                    <option value="entertainment">Entertainment</option>
                    <option value="transportation">Transportation</option>
                    <option value="other">Other</option>
                  </Select>
                  {/* <FormHelperText>Please select a category</FormHelperText> */}
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Textarea
                    id="description"
                    placeholder="Enter description"
                    size="sm"
                    maxLength={"100"}
                    variant={"filled"}
                  />
                  {/* <FormHelperText>Please enter a description</FormHelperText> */}
                </FormControl>

                {/* <FormControl mt={4}>
                  <FormLabel htmlFor="description">Attach file</FormLabel>
                  <Input
                    id="attachment"
                    type="file"
                    size="sm"
                    variant="unstyled"
                  />
                </FormControl> */}
                {/* <Box mt={4}>
                  <Button type="submit">Submit</Button>
                </Box> */}
              </form>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" size="sm" mr={3} onClick={onClose}>
              Create Request
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Spendrequest;
