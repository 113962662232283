import { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Input,
  FormHelperText,
  Box,
  Divider,
  InputLeftElement,
  InputGroup,
  Flex,
} from "@chakra-ui/react";
import { FaRoute } from "react-icons/fa";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  Polyline,
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{
          lat: (props.points[0].lat + props.points[1].lat) / 2,
          lng: (props.points[0].lng + props.points[1].lng) / 2,
        }}
      >
        {props.points.map((point, index) => (
          <Marker key={index} position={point} />
        ))}
        <Polyline path={props.points} />
      </GoogleMap>
    );
  })
);

function Distance({ clicked }) {
  const [pointA, setPointA] = useState({ lat: 40.748817, lng: -73.985428 });
  const [pointB, setPointB] = useState({ lat: 41.748817, lng: -74.985428 });

  const handleSubmit = (event) => {
    event.preventDefault();
    // Calculate distance between pointA and pointB
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  useEffect(() => {
    if (clicked && !isOpen) {
      onOpen();
    }
    return () => {
      clicked = false;
    };
  }, [clicked]);
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        closeOnOverlayClick={false}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent pt={0} pb={6}>
          <ModalHeader color="orange.800">
            <Flex>
              <Text mr={2}>New Mileage Expense </Text>
              <FaRoute />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody pb={12}>
            <Flex>
              <Box py={8} mx={12}>
                <form>
                  <FormControl isRequired>
                    <FormLabel htmlFor="merchant">Distance</FormLabel>
                    <Input
                      id="Distance"
                      placeholder="Enter distance"
                      size="sm"
                      ref={initialRef}
                      width={"auto"}
                      variant={"filled"}
                    />
                    {/* <FormHelperText>
                    Please enter the merchant name
                  </FormHelperText> */}
                  </FormControl>
                  <FormControl mt={4} isRequired>
                    <FormLabel htmlFor="date">Date</FormLabel>
                    <Input
                      id="date"
                      type="date"
                      size="sm"
                      width={"auto"}
                      variant={"filled"}
                    />
                    {/* <FormHelperText>Please enter the date</FormHelperText> */}
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel htmlFor="total">Amount</FormLabel>
                    {/* <Input id="total" type="number" size="sm" width={"auto"} /> */}
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1em"
                        children="$"
                        pb="6.5px"
                      />
                      <Input
                        placeholder="Enter amount"
                        type="number"
                        size="sm"
                        width={"auto"}
                        disabled
                      />
                    </InputGroup>
                    {/* <FormHelperText>Please enter the total amount</FormHelperText> */}
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel htmlFor="category">Category</FormLabel>
                    <Select id="category" size="sm" variant={"filled"}>
                      <option value="">Select a category</option>
                      <option value="groceries">Groceries</option>
                      <option value="entertainment">Entertainment</option>
                      <option value="transportation">Transportation</option>
                      <option value="other">Other</option>
                    </Select>
                    {/* <FormHelperText>Please select a category</FormHelperText> */}
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel htmlFor="staff">Staff Present</FormLabel>
                    <Input
                      id="staff"
                      placeholder="Enter staff names"
                      size="sm"
                      variant={"filled"}
                    />
                    <FormHelperText>
                      Please enter the staff members' name separated by comma
                    </FormHelperText>
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <Textarea
                      id="description"
                      placeholder="Enter description"
                      size="sm"
                      maxLength={"100"}
                      variant={"filled"}
                    />
                    {/* <FormHelperText>Please enter a description</FormHelperText> */}
                  </FormControl>

                  <FormControl mt={4}>
                    <FormLabel htmlFor="description">Attach file</FormLabel>
                    <Input
                      id="attachment"
                      type="file"
                      size="sm"
                      variant="unstyled"
                    />
                  </FormControl>
                  {/* <Box mt={4}>
                  <Button type="submit">Submit</Button>
                </Box> */}
                </form>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" size="sm" mr={3} onClick={onClose}>
              Create Mileage
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Map
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        points={[pointA, pointB]}
      />
    </>
  );
}

export default Distance;
