import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  InputGroup,
  Center,
  Heading,
  Image,
  Highlight,
  useToast,
  HStack,
  Icon,
  Link,
} from "@chakra-ui/react";
import logo from "../../assets/image/rm-logo.png";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import {} from "@chakra-ui/react";
import { FaTwitter, FaLinkedin, FaFacebook } from "react-icons/fa";

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const toast = useToast();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSignup = async () => {
    // Handle email sign up here
    const formData = {
      email: email,
      activated: false,
      discountCode: "",
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      toast({
        title: "Signup Failed",
        description: "Please enter a valid email",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      try {
        await addDoc(collection(db, "comingSoon"), formData).then(() => {
          // console.log("Document successfully written!");
          fetch(`https://expensehippo-server.web.app/api/coming?token=${email}`)
            .then((response) => response.json())
            .then((data) => console.log(data));

          toast({
            title: "Success!",
            description: "Please check your email 🥳",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        });
      } catch (err) {
        toast({
          title: "Signup Failed",
          description: "Error occured while signing up",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setEmail("");
      }
    }
  };

  return (
    <Flex
      minH="100vh"
      direction="column"
      align="center"
      justify="center"
      bgGradient="linear(to-br, orange.200, purple.200)"
      px={4}
    >
      <Box my={6}>
        <Center>
          <Heading as={"h1"} size={"4xl"}>
            Coming Soon
          </Heading>
        </Center>
      </Box>
      <Box
        w={{ base: "80%", md: "50%" }}
        bg="white"
        p={8}
        boxShadow="lg"
        borderRadius="md"
        textAlign="center"
      >
        <Center align={"center"} my={4}>
          <Image src={logo} boxSize={"75px"} />
          <Text fontSize="3xl" fontWeight="bold" color={"orange.500"} mb={4}>
            ExpenseHippo
          </Text>
        </Center>

        <Text fontSize="md" mb={8}>
          <Highlight
            query="10%"
            styles={{ px: "2", py: "1", rounded: "full", bg: "red.100" }}
          >
            Sign up below to get notified when we launch and receive 10% off for
            a year.
          </Highlight>
        </Text>

        <Center>
          <InputGroup mb={4} w={"75%"}>
            {/* <InputLeftAddon children="Email" /> */}
            <Input
              type="email"
              size={"md"}
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
              borderRadius="full"
              bg={"gray.100"}
            />
          </InputGroup>
        </Center>

        <Button
          borderRadius={"full"}
          colorScheme="orange"
          size="md"
          onClick={handleSignup}
        >
          Sign Up
        </Button>

        <Text fontSize={"xs"} my={4}>
          Terms & Conditions Applies.
        </Text>
      </Box>
      <Box position={"fixed"} bottom={"0"}>
        <HStack spacing="20px">
          <Link
            href="https://twitter.com/Expense_Hippo"
            isExternal
            cursor={"pointer"}
          >
            {/* <IconButton icon={<FaTwitter />} boxSize="1.1em" /> */}
            <Icon as={FaTwitter} boxSize="0.9em" cursor={"pointer"} />
          </Link>
          <Link
            href="https://linkedin.com/company/expensehippo"
            isExternal
            cursor={"pointer"}
          >
            {/* <IconButton icon={<FaLinkedin />} boxSize="1.1em" /> */}
            <Icon as={FaLinkedin} boxSize="0.9em" cursor={"pointer"} />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=100090469569603"
            isExternal
            cursor={"pointer"}
          >
            {/* <IconButton icon={<FaFacebook />} boxSize="1.1em" /> */}
            <Icon as={FaFacebook} boxSize="0.9em" cursor={"pointer"} />
          </Link>
        </HStack>
      </Box>
    </Flex>
  );
};

export default ComingSoon;
