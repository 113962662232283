// https://img.freepik.com/free-vector/realistic-receipt-template_23-2147938550.jpg
import { useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Box,
  Divider,
  Flex,
  Image,
} from "@chakra-ui/react";
import { FaReceipt } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import Moment from "react-moment";

function SpendView({ clicked, spendData }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  // alert(JSON.stringify(receiptData));

  console.log(spendData);

  useEffect(() => {
    if (clicked && !isOpen) {
      onOpen();
    }
    return () => {
      clicked = false;
    };
  }, [clicked]);
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        closeOnOverlayClick={false}
        size={"md"}
      >
        <ModalOverlay />
        <ModalContent pt={0} pb={6}>
          <ModalHeader color="orange.800">
            <Flex align={"center"}>
              <Text mr={4}>Spend Request</Text>
              <FaReceipt />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody pb={12}>
            <Box boxSize={"sm"} mx={12}>
              <Flex w={"full"} justify={"left"} align={"left"}>
                <Box py={8} mx={12}>
                  <Flex my={2}>
                    <Text fontWeight={"bold"} fontSize={"sm"}>
                      Date -
                    </Text>
                    <Text ml={2} fontSize={"sm"}>
                      {" "}
                      {/* {spendData?.date} */}
                      <Moment
                        date={new Date(spendData?.spendDate?.seconds * 1000)}
                        format="DD-MM-YYYY"
                      />
                    </Text>
                  </Flex>
                  <Flex my={2}>
                    <Text fontWeight={"bold"} fontSize={"sm"}>
                      Staff -
                    </Text>
                    <Text ml={2} fontSize={"sm"}>
                      {" "}
                      {spendData?.staff}
                    </Text>
                  </Flex>
                  <Flex my={2}>
                    <Text fontWeight={"bold"} fontSize={"sm"}>
                      Amount -
                    </Text>
                    <Text ml={2} fontSize={"sm"}>
                      {spendData?.currency} {spendData?.amount}
                    </Text>
                  </Flex>
                  <Flex my={2}>
                    <Text fontWeight={"bold"} fontSize={"sm"}>
                      Category -
                    </Text>
                    <Text ml={2} fontSize={"sm"}>
                      {" "}
                      {spendData?.category}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Divider my={4} />
              <Box>
                <Text fontWeight={"bold"} fontSize={"sm"}>
                  Description -
                </Text>
                <Text ml={2} fontSize={"sm"}>
                  {" "}
                  {spendData?.description}
                </Text>
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" size="sm" mr={3} onClick={onClose}>
              Close
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SpendView;
