import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./utils/AuthContext";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import Pricing from "./pages/Pricing/Pricing";
import Dashboard from "./pages/Dashboard/Dashboard";
import Manager from "./pages/Manager/Manager";
import Notifications from "./pages/Notifications/Notifications";
import Report from "./pages/Report/Report";
import Settings from "./pages/Settings/Settings";
import Blog from "./pages/Blog/Blog";
import Contact from "./pages/Contact/Contact";
import Blogpost from "./pages/Blogpost/Blogpost";
import Password from "./pages/Password/Password";
import Verifyemail from "./pages/VerifyEmail/Verifyemail";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
// import Services from "./pages/Solutions/solutions";
// import About from "./pages/About/about";
// import Contact from "./pages/Contact/contact";

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setCurrentUser(user);
      } else {
        // User is signed out
        setCurrentUser(null);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <Router>
      {/* <Navbar /> */}
      <AuthProvider value={{ currentUser, timeActive, setTimeActive }}>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          {/* <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<SignUp />} />
          <Route path="/auth/verifyemail" element={<Verifyemail />} />
          <Route path="/password/reset" element={<Password />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog/id/slug" element={<Blogpost />} /> */}
          {/* </Routes>
        {currentUser && (
          <Routes> */}
          {/* <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/manager" element={<Manager />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/report" element={<Report />} />
          <Route path="/settings" element={<Settings />} /> */}
        </Routes>
        {/* )} */}
      </AuthProvider>
      {/* <Footer /> */}
    </Router>
  );
};

export default App;
